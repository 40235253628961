import React from "react";

const Intro = (props) => {


    return (
        <div className="c-intro">
            <img src="https://stg-zaanstreek.acc.unframed.app/wp-content/uploads/2021/07/STGZ-logo-v3.svg" alt="" style={{width: '70rem'}}/>
            <h1>Aanvraagformulier ijsabonnement</h1>
        </div>
    )

}

export default Intro